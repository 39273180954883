import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";

import { Popconfirm } from "antd";
function AdminQuizParagraphe({ homework, exam, edit }) {
  const { lectureID, examID, n_paragraph } = useParams();

  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }
      case "setRefetchForGetInfo": {
        return {
          ...state,
          refetchForGetInfo: action.payload,
        };
      }
      case "setInfo": {
        return {
          ...state,
          info: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [SumbitEditParagraph, setSubmitEditParagraph] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successEditParagraph, errorEditParagraph, loadingEditParagraph] =
    useAxios(
      process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_EDIT_TAB_API,
      "POST",
      SumbitEditParagraph.flag,
      SumbitEditParagraph.dependency,
      SumbitEditParagraph.data,
      true
    );

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
    refetchForGetInfo: false,
  });
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");

  let QuestionDataApi = `${process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_GET_TAB_API}${n_paragraph}`;

  const [QuestionDataSuccess, QuestionErrors, QuestionDataLoading] = useAxios(
    QuestionDataApi,
    "GET",
    edit,
    refetch
  );

  useEffect(() => {
    if (QuestionDataSuccess) {
      const { paragraph } = QuestionDataSuccess?.data?.paragraph[0];
      setValues({
        paragraph,
      });
    }
  }, [QuestionDataSuccess]);

  //!--------- add edit quiz / homework info  -------

  let addEditInfoApi =
    homework && QuestionDataSuccess
      ? process.env.REACT_APP_ADMIN_EDIT_HOMEWORK_API
      : homework
      ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_API
      : QuestionDataSuccess
      ? process.env.REACT_APP_ADMIN_EDIT_QUIZ_API
      : process.env.REACT_APP_ADMIN_ADD_QUIZ_API;

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    addEditInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values,
  });
  const onSubmitEditParagraph = (data) => {
    const finalData = {
      id: n_paragraph,
      paragraph: data?.paragraph,
    };
    setSubmitEditParagraph({
      flag: "Add pragraph",
      dependency: !SumbitEditParagraph.dependency,
      data: finalData,
    });
  };
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [
    deleteParagrapheSuccess,
    deleteParagrapheErrors,
    deleteParagrapheloading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_DELETE_TAB_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteParagraphe() {
    setSubmitDelete({
      flag: "deleteParagraphe",
      dependency: !submitDelete.dependency,
      data: { id: n_paragraph },
    });
  }

  useEffect(() => {
    //!---add actions ----

    if (deleteParagrapheSuccess) {
      navigate(-1);
    }
  }, [deleteParagrapheSuccess]);

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess || loadingEditParagraph || successEditParagraph) {
      setRefetch(!refetch);
      reset();
    }
  }, [AddEditInfoSuccess, loadingEditParagraph, successEditParagraph]);

  if (QuestionDataLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col items-end gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          homework ? "الواجب" : exam ? "الإختبارات" : "الامتحان"
        } | Sir karam makram`}
      ></HelmetTags>

      <div className="flex items-center justify-start w-full">
        <Popconfirm
          okText="تأكيد"
          okType="danger"
          cancelText="إلغاء"
          color="#fecaca"
          //okButtonProps={{ loading: loading }}
          onConfirm={() => handleDeleteParagraphe()}
          title="هل تريد بالفعل حذف القطعه"
        >
          <button className="rounded-2xl border-2 border-secondary bg-secondary px-20 py-4 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
            حذف القطعه
          </button>
        </Popconfirm>
      </div>

      <div className="flex w-full items-center justify-center">
        <div className="cardS1 w-full">
          <form className="form" onSubmit={handleSubmit(onSubmitEditParagraph)}>
            <div className="flex flex-col gap-4">
              <label className="w-full truncate" htmlFor="difficulty">
                القطعه{" "}
              </label>
              <textarea
                {...register("paragraph")}
                placeholder="إضافه قظعه"
                className="h-40"
              ></textarea>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                type="submit"
                disabled={loadingEditParagraph}
                className={` login-button bg-red-00 group mt-7 rounded-xl p-3 duration-300 hover:bg-secondary   active:scale-90`}
              >
                {loadingEditParagraph ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : edit ? (
                  "تعديل"
                ) : (
                  "اضافة"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {exam || homework || edit ? (
        <>
          <div className="custom-question  w-full ">
            <h2 className="text-end text-3xl font-semibold">
              اضافة سؤال مخصص{" "}
            </h2>
            <div className="custom-question-options mt-14 flex justify-end gap-10 md:justify-center">
              <button
                onClick={() => setQuestionType("essay")}
                className={`  rounded-2xl border-2 border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light active:scale-90 ${
                  questionType === "essay" ? "bg-secondary text-light" : ""
                }`}
              >
                سؤال مقالي
              </button>
              <button
                onClick={() => setQuestionType("choose")}
                className={`  rounded-2xl border-2  border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light  active:scale-90 ${
                  questionType === "choose" ? "bg-secondary text-light" : ""
                }`}
              >
                سؤال اختيارات
              </button>
            </div>
            {questionType === "choose" ? (
              <AddEditChooseQuestion
                type="paragraph"
                refetch={refetch}
                setRefetch={setRefetch}
              />
            ) : (
              <AddEditEssayQuestion
                type="paragraph"
                // id={
                //   homework
                //     ? QuestionDataSuccess?.data?.key
                //     : QuestionDataSuccess?.data?.quiz_info[0]?.key
                // }
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}
          </div>
          {QuestionDataSuccess?.data?.questions?.length > 0 && (
            <div className="all-custom-questions w-full ">
              <h2 className="text-end text-3xl font-semibold">
                {" "}
                الأسئلة المخصصة{" "}
              </h2>

              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                // id={
                //   homework
                //     ? QuestionDataSuccess?.data?.homework_info[0]?.key
                //     : QuestionDataSuccess?.data?.quiz_info[0]?.key
                // }
                QuizQuestionsData={QuestionDataSuccess?.data?.questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
              />
            </div>
          )}
        </>
      ) : null}
    </section>
  );
}

export default AdminQuizParagraphe;
