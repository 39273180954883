import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AddSetsForm from "./AddSetsForm.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import SetsTable from "./SetsTable";
import { Link } from "react-router-dom";
import TranslationsTable from "./TranslationsTable.jsx";
import AddTranslate from "./AddTranslate.jsx";

import QuestionsModal from "./modal/QuestionsModal.jsx";
import { Popconfirm } from "antd";
function AdminQuiz({ homework, exam, edit, lectureQuestion, QuestionBank }) {
  const { lectureID, examID, categoryID } = useParams();
  const AuthState = useAuthState();
  const [showModal, setShowModal] = useState(false);

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }
      case "setRefetchForGetInfo": {
        return {
          ...state,
          refetchForGetInfo: action.payload,
        };
      }
      case "setInfo": {
        return {
          ...state,
          info: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [SumbitAddParagraph, setSubmitAddParagraph] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let AddApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_PARAGRAPH_ADD_TAB_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_ADD_TAB_API
    : QuestionBank
    ? ""
    : process.env.REACT_APP_STUDENT_QUIZ_PARAGRAPH_ADD_TAB_API;

  const [successAddParagraph, errorAddParagraph, loadingAddParagraph] =
    useAxios(
      AddApi,
      "POST",
      SumbitAddParagraph.flag,
      SumbitAddParagraph.dependency,
      SumbitAddParagraph.data,
      true
    );

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
    refetchForGetInfo: false,
  });
  const [values, setValues] = useState({});
  const [translateData, setTranslateData] = useState();
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------

  let InfoApi = homework
    ? `${process.env.REACT_APP_ADMIN_LECTURE_HOMEWORK_API}${lectureID}`
    : exam
    ? `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${examID}`
    : QuestionBank
    ? ""
    : `${process.env.REACT_APP_ADMIN_LECTURE_QUIZ_API}${lectureID}`;
  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    InfoApi,
    "GET",
    "GET",
    refetch
  );

  // delete paragraphe
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [
    deleteParagrapheSuccess,
    deleteParagrapheErrors,
    deleteParagrapheloading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_DELETE_TAB_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteParagraphe(id) {
    setSubmitDelete({
      flag: "deleteParagraphe",
      dependency: !submitDelete.dependency,
      data: { id },
    });
  }

  useEffect(() => {
    //!---add actions ----

    if (deleteParagrapheSuccess) {
      setRefetch(!refetch);
    }
  }, [deleteParagrapheSuccess]);

  useEffect(() => {
    if (getInfoSuccess) {
      if (homework) {
        const { title, visibility, success_rate } =
          getInfoSuccess?.data?.homework_info[0];
        setValues({
          title,
          success_rate,
          visibility: visibility === 1 ? true : false,
        });
      } else {
        const { duration, visibility, success_rate, to_center, v_model, name } =
          getInfoSuccess?.data?.quiz_info[0];
        setValues({
          name,
          duration,
          visibility: visibility === 1 ? true : false,
          to_center: to_center === 1 ? true : false,
          v_model: v_model === 1 ? true : false,
          success_rate,
        });
      }
    }
  }, [getInfoSuccess]);

  //!--------- add edit quiz / homework info  -------

  let addEditInfoApi =
    homework && getInfoSuccess
      ? process.env.REACT_APP_ADMIN_EDIT_HOMEWORK_API
      : homework
      ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_API
      : getInfoSuccess
      ? process.env.REACT_APP_ADMIN_EDIT_QUIZ_API
      : process.env.REACT_APP_ADMIN_ADD_QUIZ_API;

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    addEditInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values,
  });
  const formparagraph = useForm({
    mode: "onTouched",
  });
  const onSubmitAddParagraph = (data) => {
    const finalData = examID
      ? {
          exam_id: examID,
          paragraph: data.paragrapgh,
        }
      : homework
      ? {
          homework_id: lectureID,
          paragraph: data.paragrapgh,
        }
      : QuestionBank
      ? {
          category_id: categoryID,
          paragraph: data.paragrapgh,
        }
      : {
          quiz_id: lectureID,
          paragraph: data.paragrapgh,
        };
    setSubmitAddParagraph({
      flag: "Add pragraph",
      dependency: !SumbitAddParagraph.dependency,
      data: finalData,
    });
  };

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess || loadingAddParagraph) {
      setRefetch(!refetch);
      reset();
    }
  }, [AddEditInfoSuccess, loadingAddParagraph]);

  function onSubmitAddEditInfo(data) {
    if (homework) {
      const { title, visibility, success_rate } = data;
      let finalData = getInfoSuccess
        ? {
            homework_id: getInfoSuccess?.data?.homework_info[0].key,
            title,
            visibility: visibility === true ? 1 : 0,
            success_rate,
          }
        : { lecture_id: lectureID, ...data };

      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    } else {
      const { duration, visibility, success_rate, to_center, v_model, name } =
        data;
      let finalData = getInfoSuccess
        ? {
            name,
            quiz_id: getInfoSuccess?.data?.quiz_info[0]?.key,
            duration: duration,
            visibility: visibility === true ? 1 : 0,
            to_center: to_center === true ? 1 : 0,
            v_model: v_model === true ? 1 : 0,
            success_rate,
          }
        : { lecture_id: lectureID, ...data };
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    }
  }

  return (
    <section className="flex h-auto w-full flex-col items-end gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10 z-[999]">
      <HelmetTags
        title={`${
          homework ? "الواجب" : exam ? "الإختبارات" : "الامتحان"
        } | Sir karam makram`}
      ></HelmetTags>
      <div className="w-full flex justify-center flex-wrap gap-5 items-center">
        {getInfoSuccess?.data?.Questions?.length > 0 && (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="bg-secondary text-white hover:bg-white hover:text-secondary border border-secondary duration-200 px-4 py-3 rounded-2xl active:scale-95"
          >
            معاينة الامتحان
          </button>
        )}
        <QuestionsModal
          QuizQuestionsData={getInfoSuccess?.data?.Questions}
          paragrapghQuestions={getInfoSuccess?.data?.all_paragrapghs}
          setShowModal={setShowModal}
          showModal={showModal}
          setTranslateData={setTranslateData}
          translateData={translateData}
        />
      </div>
      <div className="w-full flex justify-center flex-wrap gap-5 items-center">
        {getInfoSuccess?.data?.all_paragrapghs?.map((item, idx) => (
          <Link
            to={`/admin/${homework ? "homework" : exam ? "exams" : "quiz"}/20/${
              item.id
            }`}
            className="bg-secondary text-white hover:bg-white hover:text-secondary border border-secondary duration-200 px-4 py-3 rounded-md"
          >
            {`${idx + 1} قطعة`}
          </Link>
        ))}
      </div>
      <div className="w-full flex justify-center flex-wrap gap-5 items-center">
        {getInfoSuccess?.data?.paragrapghs?.map((item, idx) => (
          <Link
            to={`/admin/quiz/20/${item.id}`}
            className="bg-secondary text-white hover:bg-white hover:text-secondary border border-secondary duration-200 px-4 py-3 rounded-md"
          >
            {`${idx + 1} قطعة`}
          </Link>
        ))}
      </div>
      {/* {getInfoSuccess?.data?.Questions?.length > 0 && ( */}
      <div className="flex w-full items-center justify-center">
        <div className="cardS1 w-full">
          <form
            className="form"
            onSubmit={formparagraph.handleSubmit(onSubmitAddParagraph)}
          >
            <div className="flex flex-col gap-4">
              <label className="w-full truncate" htmlFor="difficulty">
                القطعه{" "}
              </label>
              <textarea
                {...formparagraph.register("paragrapgh", {
                  required: true,
                  min: 3,
                })}
                placeholder="إضافه قظعه"
                className="h-40"
                minLength={3}
              ></textarea>
              {errors.stage && (
                <p className="text-[12px] text-red-900 ">
                  {errors.stage.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                  {errors.stage.min && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                type="submit"
                disabled={loadingAddParagraph}
                className={` login-button bg-red-00 group mt-7 rounded-xl p-3 duration-300 hover:bg-secondary   active:scale-90`}
              >
                {loadingAddParagraph ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "اضافة"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* )} */}

      {!exam && !QuestionBank && (
        <div className="add-quiz-homework flex w-full flex-col items-center gap-14 md:items-center">
          <h2 className="w-full text-end text-3xl font-semibold">
            {getInfoSuccess && homework
              ? "تعديل الواجب"
              : getInfoSuccess
              ? "تعديل الإمتحان"
              : homework
              ? "اضافة واجب"
              : "اضافة امتحان"}
          </h2>

          {/**
           * //!--- add exam duration or homework name -------
           */}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            (homework &&
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_homeworks_permissions")
              )) ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_quizzes_permissions")
            )) && (
            <div className="cardS1 w-full">
              <form
                method="post"
                noValidate
                onSubmit={handleSubmit(onSubmitAddEditInfo)}
                className="form"
              >
                {homework ? (
                  <>
                    <div className="title flex w-full flex-col items-start  justify-center  gap-10">
                      <label htmlFor="title">الإسم</label>
                      <input
                        className="input w-full pl-4  mt-2"
                        type="text"
                        id="title"
                        placeholder="الإسم"
                        title="title"
                        autoComplete="on"
                        {...register("title", {
                          required: true,
                          //pattern: /^[A-Za-z]+$/,
                          maxLength: 255,
                          minLength: 3,
                        })}
                      />
                      {errors.title && (
                        <p className="w-full text-end text-[12px] text-red-900  ">
                          {errors.title.type === "required" &&
                            "برجاء ملئ هذا الحقل"}
                          {errors.title.type === "pattern" &&
                            "يسمح فقط باستخدام الحروف الإنجليزية"}
                          {errors.title.type === "maxLength" &&
                            "أقصي عدد للحروف هو ٢٢٥ حرف"}
                          {errors.title.type === "minLength" &&
                            "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                        </p>
                      )}
                    </div>
                    <div className="flex w-full flex-col items-start  justify-center  gap-2">
                      <label htmlFor="success_rate">نسبه النجاح</label>
                      <input
                        className="input w-full pl-4 mt-2"
                        type="number"
                        id="success_rate"
                        placeholder="نسبه النجاح"
                        title="success_rate"
                        autoComplete="on"
                        {...register("success_rate", {
                          required: true,
                          //pattern: /^[A-Za-z]+$/,
                          minLength: 1,
                        })}
                      />
                      {errors.title && (
                        <p className="w-full text-end text-[12px] text-red-900  ">
                          {errors.success_rate.type === "required" &&
                            "برجاء ملئ هذا الحقل"}
                        </p>
                      )}
                    </div>

                    {getInfoSuccess?.is_found === 1 && (
                      <div className=" visibility flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
                        <div className="flex w-full items-center justify-end gap-10">
                          <label className="visibility-switch">
                            <input
                              className=""
                              id="visibility"
                              name="visibility"
                              {...register("visibility", {
                                required: false,
                              })}
                              type="checkbox"
                            />
                            <span className="visibility-slider">
                              <svg
                                className="slider-icon"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="presentation"
                              >
                                <path fill="none" d="m4 16.5 8 8 16-16"></path>
                              </svg>
                            </span>
                          </label>
                          <div>
                            <label
                              className="w-full cursor-pointer truncate"
                              htmlFor="visibility"
                            >
                              الظهور{" "}
                            </label>
                          </div>
                        </div>

                        {errors.visibility && (
                          <p className="text-[12px] text-red-900 ">
                            {errors.visibility.type === "required" &&
                              "برجاء ملئ هذا الحقل"}{" "}
                          </p>
                        )}
                        {
                          //!-------server errors -----

                          AddEditInfoErrors &&
                            AddEditInfoErrors?.response?.data?.errors
                              ?.visibility && (
                              <p className="w-full text-end text-[12px] text-red-900  ">
                                {
                                  AddEditInfoErrors?.response?.data?.errors
                                    ?.visibility[0]
                                }
                              </p>
                            )
                        }
                      </div>
                    )}

                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors?.title && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.title[0]
                            }
                          </p>
                        )
                    }
                  </>
                ) : (
                  <div className="flex  w-full items-center justify-evenly gap-10 flex-col-reverse">
                    {getInfoSuccess?.is_found === 1 && (
                      <>
                        <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full ">
                          <div className="flex w-full items-center justify-end gap-10">
                            <label className="visibility-switch">
                              <input
                                className=""
                                id="visibility"
                                name="visibility"
                                {...register("visibility", {
                                  required: false,
                                })}
                                type="checkbox"
                              />
                              <span className="visibility-slider">
                                <svg
                                  className="slider-icon"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  role="presentation"
                                >
                                  <path
                                    fill="none"
                                    d="m4 16.5 8 8 16-16"
                                  ></path>
                                </svg>
                              </span>
                            </label>
                            <div>
                              <label
                                className="w-full cursor-pointer truncate"
                                htmlFor="visibility"
                              >
                                الظهور{" "}
                              </label>
                            </div>
                          </div>

                          {errors.visibility && (
                            <p className="text-[12px] text-red-900 ">
                              {errors.visibility.type === "required" &&
                                "برجاء ملئ هذا الحقل"}{" "}
                            </p>
                          )}
                          {
                            //!-------server errors -----

                            AddEditInfoErrors &&
                              AddEditInfoErrors?.response?.data?.errors
                                ?.visibility && (
                                <p className="w-full text-end text-[12px] text-red-900  ">
                                  {
                                    AddEditInfoErrors?.response?.data?.errors
                                      ?.visibility[0]
                                  }
                                </p>
                              )
                          }
                        </div>
                        <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full ">
                          <div className="flex w-full items-center justify-end gap-10">
                            <label className="visibility-switch">
                              <input
                                className=""
                                id="to_center"
                                name="to_center"
                                {...register("to_center", {
                                  required: false,
                                })}
                                type="checkbox"
                              />
                              <span className="visibility-slider">
                                <svg
                                  className="slider-icon"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  role="presentation"
                                >
                                  <path
                                    fill="none"
                                    d="m4 16.5 8 8 16-16"
                                  ></path>
                                </svg>
                              </span>
                            </label>
                            <div>
                              <label
                                className="w-full cursor-pointer truncate"
                                htmlFor="to_center"
                              >
                                سنتر{" "}
                              </label>
                            </div>
                          </div>

                          {errors.to_center && (
                            <p className="text-[12px] text-red-900 ">
                              {errors.to_center.type === "required" &&
                                "برجاء ملئ هذا الحقل"}{" "}
                            </p>
                          )}
                          {
                            //!-------server errors -----

                            AddEditInfoErrors &&
                              AddEditInfoErrors?.response?.data?.errors
                                ?.to_center && (
                                <p className="w-full text-end text-[12px] text-red-900  ">
                                  {
                                    AddEditInfoErrors?.response?.data?.errors
                                      ?.to_center[0]
                                  }
                                </p>
                              )
                          }
                        </div>
                        <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full ">
                          <div className="flex w-full items-center justify-end gap-10">
                            <label className="visibility-switch">
                              <input
                                className=""
                                id="v_model"
                                name="v_model"
                                {...register("v_model", {
                                  required: false,
                                })}
                                type="checkbox"
                              />
                              <span className="visibility-slider">
                                <svg
                                  className="slider-icon"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  role="presentation"
                                >
                                  <path
                                    fill="none"
                                    d="m4 16.5 8 8 16-16"
                                  ></path>
                                </svg>
                              </span>
                            </label>
                            <div>
                              <label
                                className="w-full cursor-pointer truncate"
                                htmlFor="v_model"
                              >
                                نموذج الاجابة{" "}
                              </label>
                            </div>
                          </div>

                          {errors.v_model && (
                            <p className="text-[12px] text-red-900 ">
                              {errors.v_model.type === "required" &&
                                "برجاء ملئ هذا الحقل"}{" "}
                            </p>
                          )}
                          {
                            //!-------server errors -----

                            AddEditInfoErrors &&
                              AddEditInfoErrors?.response?.data?.errors
                                ?.v_model && (
                                <p className="w-full text-end text-[12px] text-red-900  ">
                                  {
                                    AddEditInfoErrors?.response?.data?.errors
                                      ?.v_model[0]
                                  }
                                </p>
                              )
                          }
                        </div>
                      </>
                    )}
                    <div
                      //!--duration--
                      className=" duration flex  w-full flex-col items-end justify-center gap-2 md:w-full"
                    >
                      <label className="w-fit truncate" htmlFor="duration">
                        مدة الإمتحان بالدقائق{" "}
                      </label>
                      <input
                        className="input w-full "
                        type="number"
                        min={1}
                        id="seconds"
                        max={59}
                        placeholder="دقيقة"
                        name="seconds"
                        autoComplete="on"
                        {...register("duration", {
                          valueAsNumber: true,
                          min: 1,
                          required: true,
                        })}
                      />

                      {errors.duration && (
                        <>
                          <p className="text-[12px] text-red-900 ">
                            {errors.duration?.type === "required" &&
                              "برجاء ملئ هذا الحقل"}{" "}
                            {errors.duration?.type === "min" &&
                              "من فضلك ادخل رقم اكبر من واحد"}{" "}
                          </p>
                        </>
                      )}
                      {
                        //!-------server errors -----

                        AddEditInfoErrors &&
                          AddEditInfoErrors?.response?.data?.errors
                            ?.duration && (
                            <p className="w-full text-end text-[12px] text-red-900  ">
                              {
                                AddEditInfoErrors?.response?.data?.errors
                                  ?.duration[0]
                              }
                            </p>
                          )
                      }
                    </div>
                    <div
                      //!--duration--
                      className="success_rate flex  w-full flex-col items-end justify-center gap-2 md:w-full"
                    >
                      <label className="w-fit truncate" htmlFor="success_rate">
                        نسبه النجاح
                      </label>
                      <input
                        className="input w-full "
                        type="number"
                        min={1}
                        id="success_rate"
                        max={100}
                        placeholder="%"
                        name="success_rate"
                        autoComplete="on"
                        {...register("success_rate", {
                          valueAsNumber: true,
                          min: 1,
                          required: true,
                        })}
                      />

                      {errors.success_rate && (
                        <>
                          <p className="text-[12px] text-red-900 ">
                            {errors.success_rate?.type === "required" &&
                              "برجاء ملئ هذا الحقل"}
                          </p>
                        </>
                      )}
                      {
                        //!-------server errors -----

                        AddEditInfoErrors &&
                          AddEditInfoErrors?.response?.data?.errors
                            ?.duration && (
                            <p className="w-full text-end text-[12px] text-red-900  ">
                              {
                                AddEditInfoErrors?.response?.data?.errors
                                  ?.duration[0]
                              }
                            </p>
                          )
                      }
                    </div>
                    <div
                      //!--duration--
                      className=" flex  w-full flex-col items-end justify-center gap-2 md:w-full"
                    >
                      <label className="w-fit truncate" htmlFor="name">
                        الاسم
                      </label>
                      <input
                        className="input w-full "
                        type="text"
                        id="name"
                        placeholder="الاسم"
                        name="name"
                        autoComplete="on"
                        {...register("name", {
                          required: true,
                        })}
                      />

                      {errors.name && (
                        <>
                          <p className="text-[12px] text-red-900 ">
                            {errors.name?.type === "required" &&
                              "برجاء ملئ هذا الحقل"}
                          </p>
                        </>
                      )}
                      {
                        //!-------server errors -----

                        AddEditInfoErrors &&
                          AddEditInfoErrors?.response?.data?.errors
                            ?.duration && (
                            <p className="w-full text-end text-[12px] text-red-900  ">
                              {
                                AddEditInfoErrors?.response?.data?.errors
                                  ?.duration[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  </div>
                )}

                {/** submit */}

                <button
                  disabled={
                    !isValid ||
                    (AddEditInfoLoading && state.submitAddEditInfo.flag)
                  }
                  className=" login-button  mt-6 w-full "
                  type="submit"
                >
                  {AddEditInfoLoading && state.submitAddEditInfo.flag ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <p>
                      {" "}
                      {getInfoSuccess?.is_found === 1 && homework
                        ? "تعديل الواجب"
                        : getInfoSuccess?.is_found === 1
                        ? "تعديل الإمتحان"
                        : homework
                        ? "اضافة واجب"
                        : "اضافة امتحان"}
                    </p>
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      )}

      {exam || QuestionBank || getInfoSuccess?.is_found === 1 ? (
        <>
          {exam ||
            (getInfoSuccess?.is_found === 1 && (
              <>
                <div className="category-questions flex w-full flex-col items-end gap-14 md:items-center">
                  <h2 className="text-end text-3xl font-semibold">
                    اختر من تصنيفات بنك الأسئلة
                  </h2>

                  <AddSetsForm
                    type={homework ? "homework" : exam ? "exam" : "quiz"}
                    id={
                      homework
                        ? getInfoSuccess?.data?.homework_info[0].key
                        : getInfoSuccess?.data?.quiz_info[0]?.key
                    }
                    setRefetch={setRefetch}
                    refetch={refetch}
                  />
                </div>
                <div className="category-questions flex w-full flex-col items-end gap-14 md:items-center">
                  <h2 className="text-end text-3xl font-semibold">
                    اضافة ترجمة
                  </h2>

                  <AddTranslate
                    type={homework ? "homework" : exam ? "exam" : "quiz"}
                    id={
                      homework
                        ? getInfoSuccess?.data?.homework_info[0].key
                        : getInfoSuccess?.data?.quiz_info[0]?.key
                    }
                    setRefetch={setRefetch}
                    refetch={refetch}
                  />
                </div>

                <div className="category-questions flex w-full flex-col items-center gap-2 md:items-center ">
                  {homework &&
                    getInfoSuccess?.data &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("lecture_addHw")
                      )) && (
                      <>
                        <h2 className="text-center text-2xl font-semibold">
                          بيانات جميع الترجمة المجمعة
                        </h2>

                        <TranslationsTable
                          setRefetch={setRefetch}
                          refetch={refetch}
                          id={getInfoSuccess?.data?.homework_info[0].key}
                          tableFor={"homeworkTranslations"}
                          setTranslateData={setTranslateData}
                          translateData={translateData}
                        />
                      </>
                    )}
                  {!homework &&
                    !exam &&
                    getInfoSuccess?.data &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("lecture_addquiz")
                      )) && (
                      <>
                        <h2 className="text-center text-2xl font-semibold">
                          بيانات جميع الترجمة المجمعة
                        </h2>

                        <TranslationsTable
                          id={getInfoSuccess?.data?.quiz_info[0]?.key}
                          setRefetch={setRefetch}
                          tableFor={"quizTranslations"}
                          refetch={refetch}
                          setTranslateData={setTranslateData}
                          translateData={translateData}
                        />
                      </>
                    )}
                  {exam &&
                    getInfoSuccess?.data &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("lecture_addHw")
                      )) && (
                      <>
                        <h2 className="text-center text-2xl font-semibold">
                          بيانات جميع الترجمة المجمعة
                        </h2>

                        <TranslationsTable
                          id={getInfoSuccess?.data?.quiz_info[0].key}
                          tableFor={"examTranslations"}
                          setRefetch={setRefetch}
                          refetch={refetch}
                          setTranslateData={setTranslateData}
                          translateData={translateData}
                        />
                      </>
                    )}
                </div>
              </>
            ))}

          {getInfoSuccess?.data?.sets?.length > 0 && (
            <div className="category-questions flex w-full flex-col items-center gap-2 md:items-center ">
              {homework &&
                (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <>
                    <h2 className="text-center text-2xl font-semibold">
                      بيانات جميع الأسئلة المجمعة
                    </h2>

                    <SetsTable
                      id={getInfoSuccess?.data?.homework_info[0].key}
                      tableFor={"homeworkSets"}
                    />
                  </>
                )}
              {!homework &&
                !exam &&
                (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <>
                    <h2 className="text-center text-2xl font-semibold">
                      بيانات جميع الأسئلة المجمعة
                    </h2>

                    <SetsTable
                      id={getInfoSuccess?.data?.quiz_info[0]?.key}
                      tableFor={"quizSets"}
                    />
                  </>
                )}
              {exam &&
                (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <>
                    <h2 className="text-center text-2xl font-semibold">
                      بيانات جميع الأسئلة المجمعة
                    </h2>

                    <SetsTable
                      id={getInfoSuccess?.data?.quiz_info[0].key}
                      tableFor={"examSets"}
                    />
                  </>
                )}
            </div>
          )}

          <div className="custom-question  w-full ">
            <h2 className="text-end text-3xl font-semibold">
              اضافة سؤال مخصص{" "}
            </h2>
            <div className="custom-question-options mt-14 flex justify-end gap-10 md:justify-center">
              <button
                onClick={() => setQuestionType("essay")}
                className={`  rounded-2xl border-2 border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light active:scale-90 ${
                  questionType === "essay" ? "bg-secondary text-light" : ""
                }`}
              >
                سؤال مقالي
              </button>
              <button
                onClick={() => setQuestionType("choose")}
                className={`  rounded-2xl border-2  border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light  active:scale-90 ${
                  questionType === "choose" ? "bg-secondary text-light" : ""
                }`}
              >
                سؤال اختيارات
              </button>
            </div>
            {questionType === "choose" ? (
              <AddEditChooseQuestion
                type={
                  QuestionBank
                    ? false
                    : homework
                    ? "homework"
                    : exam
                    ? "exam"
                    : "quiz"
                }
                allParagraphs={
                  getInfoSuccess?.data?.all_paragrapghs ||
                  getInfoSuccess?.data?.paragrapghs
                }
                id={
                  QuestionBank
                    ? false
                    : homework
                    ? getInfoSuccess?.data?.homework_info[0].key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                refetch={refetch}
                setRefetch={setRefetch}
              />
            ) : (
              <AddEditEssayQuestion
                type={QuestionBank ? false : homework ? "homework" : "quiz"}
                allParagraphs={getInfoSuccess?.data?.all_paragrapghs}
                id={
                  QuestionBank
                    ? false
                    : homework
                    ? getInfoSuccess?.data?.key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}
          </div>
          {getInfoSuccess?.data?.Questions?.length > 0 && (
            <div className="all-custom-questions w-full ">
              <h2 className="text-end text-3xl font-semibold">
                {" "}
                الأسئلة المخصصة{" "}
              </h2>

              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                id={
                  homework
                    ? getInfoSuccess?.data?.homework_info[0].key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
                lectureQuestion={lectureQuestion}
                homeworkID={lectureID}
                quizID={examID}
              />
            </div>
          )}

          {getInfoSuccess?.data?.all_paragrapghs?.length > 0 && (
            <>
              {getInfoSuccess?.data?.all_paragrapghs.map((paragrapgh) => (
                <div className="border-2 rounded-3xl p-6 w-full">
                  <div className="border-2 w-full rounded-3xl p-6 flex items-center justify-between text-start">
                    <div className="flex items-center gap-6">
                      <Popconfirm
                        okText="تأكيد"
                        okType="danger"
                        cancelText="إلغاء"
                        color="#fecaca"
                        //okButtonProps={{ loading: loading }}
                        onConfirm={() => handleDeleteParagraphe(paragrapgh.id)}
                        title="هل تريد بالفعل حذف القطعه"
                      >
                        <button className="deleteQuestionBtn">حذف</button>
                      </Popconfirm>{" "}
                      <Link
                        to={`/admin/exams/${examID}/${paragrapgh.id}`}
                        className="editQuestionBtn "
                      >
                        تعديل
                      </Link>
                    </div>
                    <p className="text-3xl font-bold ">
                      {paragrapgh.paragraph}
                    </p>
                  </div>
                  <QuestionBankCategory
                    type={homework ? "homework" : "quiz"}
                    id={
                      homework
                        ? getInfoSuccess?.data?.homework_info[0].key
                        : getInfoSuccess?.data?.quiz_info[0]?.key
                    }
                    QuizQuestionsData={paragrapgh.questions}
                    setRefetchQuiz={setRefetch}
                    refetchQuiz={refetch}
                    lectureQuestion={lectureQuestion}
                    homeworkID={lectureID}
                    quizID={examID}
                  />
                </div>
              ))}
            </>
          )}
        </>
      ) : null}
    </section>
  );
}

export default AdminQuiz;
