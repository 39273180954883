import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import QuestionBankCategory_modal from "./modals/QuestionBankCategory_modal.jsx";
import { Popconfirm } from "antd";

function QuestionBankCategory({
  type,
  QuizQuestionsData,
  refetchQuiz,
  setRefetchQuiz,
  id,
  lectureQuestion,
  homeworkID,
  quizID,
}) {
  const Modal = useRef(null);
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const { categoryID } = useParams();
  const [editingId, setEditingId] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState({});
  const [editedAnswers, setEditedAnswers] = useState({});
  const [isEssay, setIsEssay] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [allQuestionsData, allQuestionsErrors, loading] = useAxios(
    `${process.env.REACT_APP_CATEGORY_QUESTIONS_API}${categoryID}`,
    "GET",
    !type,
    reFetch
  );

  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_QUESTION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_EDIT_QUESTION_API,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  useEffect(() => {
    if (addEditQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
    }
  }, [addEditQuestionSuccess]);

  useEffect(() => {
    if (deleteQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
      setDeleteId("");
    }
  }, [deleteQuestionSuccess]);

  const handleDelete = () => {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id: deleteId },
    });
  };

  const handleEditClick = (ques) => {
    setEditingId(ques.key);
    setEditedQuestion({
      question: ques.question,
      difficulty: ques.difficulty,
      degree: ques.degree,
      correct_answer: ques.correct_answer,
    });
    if (ques.is_essay) {
      setEditedAnswers({});
    } else {
      setEditedAnswers({
        answer1: ques.answer1,
        answer2: ques.answer2,
        answer3: ques.answer3,
        answer4: ques.answer4,
      });
    }
  };

  // Handle Input Changes

  const handleAnswerChange = (index, value) => {
    setEditedAnswers((prev) => ({
      ...prev,
      [`answer${index + 1}`]: value,
    }));
  };

  // Save Edited Data
  const handleSaveClick = (ques) => {
    const finalData = isEssay
      ? {
          question: editedQuestion.question,
          difficulty: editedQuestion.difficulty,
          degree: editedQuestion.degree,
          correct_answer: editedQuestion.correct_answer,
          id: ques.key,
        }
      : {
          question: editedQuestion.question,
          answer1: editedAnswers.answer1,
          answer2: editedAnswers.answer2,
          answer3: editedAnswers.answer3,
          answer4: editedAnswers.answer4,
          difficulty: editedQuestion.difficulty,
          degree: editedQuestion.degree,
          correct_answer: editedQuestion.correct_answer,
          id: ques.key,
        };

    setEditingId(null);
    setSubmitQuestion({
      flag: "Edit Question",
      dependency: !submitQuestion.dependency,
      data: finalData,
    });
  };
  // Cancel Editing
  const handleCancelClick = () => {
    setEditingId(null);
  };
  const renderOptions = (ques) => (
    <div className="questionOptions">
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_deletequestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )) && (
        <button
          className="deleteQuestionBtn"
          onClick={() => {
            Modal.current.classList.remove("hide");
            setDeleteId(ques.key);
          }}
        >
          حذف
        </button>
      )}

      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )) && (
        <>
          {editingId === ques.key ? (
            <>
              <button
                className="px-3 py-2 rounded-3xl border border-secondary text-secondary active:scale-95"
                onClick={() => handleCancelClick()}
              >
                الغاء
              </button>
              <button
                className="editQuestionBtn"
                onClick={() => handleSaveClick(ques)}
              >
                حفظ
              </button>
            </>
          ) : (
            <button
              onClick={() => handleEditClick(ques)}
              className="editQuestionBtn"
            >
              تعديل
            </button>
          )}
        </>
      )}
    </div>
  );

  const renderAnswers = (ques) => {
    if (ques.is_essay) return null;
    const EnglishLetters = ["A -", "B -", "C -", "D -"];

    return (
      <div>
        <div className="categoryAnswersWrapper">
          {["answer1", "answer2", "answer3", "answer4"].map(
            (answerKey, index) => (
              <div
                key={answerKey}
                className={`categorySingleAnswer ${
                  answerKey === ques.correct_answer && "correctSingleAnswer"
                } `}
              >
                <p>{EnglishLetters[index]}</p>
                {editingId === ques.key ? (
                  <input
                    type="text"
                    className="signin-inputs"
                    value={editedAnswers[answerKey]}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: ques[answerKey] }} />
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const renderQuestions = (questions) => {
    return questions?.map((ques) => (
      <div key={ques.key} className="singleQuestionWrapper">
        <div className="optionsQuestionWrapper">
          {renderOptions(ques)}
          <div className="categoryQuestionWrapper">
            <div className="essayTitleWrapper">
              <p>{`السؤال رقم ${ques.n}`}</p>
            </div>

            {editingId === ques.key ? (
              <>
                <input
                  type="text"
                  value={editedQuestion.question}
                  onChange={(e) =>
                    setEditedQuestion((prev) => ({
                      ...prev,
                      question: e.target.value,
                    }))
                  }
                  className="signin-inputs"
                />
                <input
                  type="text"
                  value={editedQuestion.degree}
                  onChange={(e) =>
                    setEditedQuestion((prev) => ({
                      ...prev,
                      degree: e.target.value,
                    }))
                  }
                  className="signin-inputs"
                  placeholder="Degree"
                />
                <select
                  onChange={(e) =>
                    setEditedQuestion((prev) => ({
                      ...prev,
                      correct_answer: e.target.value,
                    }))
                  }
                  className="input"
                  name="correct_answer"
                  id="correct_answer"
                  defaultValue={editedQuestion.correct_answer}
                >
                  <option value="answer1">الإجابة الأولي</option>
                  <option value="answer2">الإجابة الثانية</option>
                  <option value="answer3">الإجابة الثالثة</option>
                  <option value="answer4">الإجابة الرابعة</option>
                </select>
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: ques.question }} />
            )}
          </div>
        </div>
        {renderAnswers(ques)}
      </div>
    ));
  };
  return (
    <section
      className={`h-full w-full overflow-y-auto ${
        !type && "px-20"
      } py-20 dark:bg-dark dark:text-light sm:px-10`}
    >
      {!type && <HelmetTags title="بنك الاسئلة | Sir karam makram" />}
      <div
        ref={Modal}
        className="modal hide absolute bottom-1/2 right-1/2 w-80 translate-x-1/2 translate-y-1/2 flex-col items-center gap-4 rounded-2xl bg-red-200 p-4"
      >
        <p className="mb-4 w-full text-center">تأكيد اجراء الحذف؟</p>
        <div className="flex items-center justify-center gap-5">
          <button
            onClick={() => {
              Modal.current.classList.add("hide");
              setDeleteId("");
            }}
            className="closeModal"
          >
            الغاء
          </button>
          <button onClick={handleDelete} className="confirmModal">
            تأكيد
          </button>
        </div>
      </div>
      {!type && allQuestionsData?.name && (
        <h2 className="mb-16 w-full text-end text-4xl font-bold sm:text-center">
          اسئلة تصنيف {allQuestionsData.name}
        </h2>
      )}
      <div className="all-questions flex h-auto w-full flex-col gap-14 px-10">
        {renderQuestions(
          QuizQuestionsData?.length > 0
            ? QuizQuestionsData
            : allQuestionsData?.data
        )}
        {allQuestionsErrors && (
          <p className="w-full text-center text-red-500">
            {allQuestionsErrors}
          </p>
        )}
      </div>
    </section>
  );
}

export default QuestionBankCategory;
