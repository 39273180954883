import { Input, Modal, Table } from "antd";
import React, { useState } from "react";

export default function QuestionsModal({
  showModal,
  setShowModal,
  QuizQuestionsData,
  paragrapghQuestions,
  translateData,
}) {
  const [translations, setTranslations] = useState({});

  const handleTranslationChange = (id, value) => {
    setTranslations((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Prepare data for the table
  const tableData = translateData?.flatMap((item) =>
    item.translation_questions.map((question) => ({
      key: question.id, // Unique key for each row
      id: question.id,
      question: question.question,
      degree: question.degree,
      translation: translations[question.id] || "",
    }))
  );

  const Translationcolumns = [
    {
      width: 100,
      title: "الترجمة",
      key: "translation",
      align: "right",
      render: (_, record) => (
        <Input
          placeholder="أدخل الترجمة"
          value={record.translation}
          onChange={(e) => handleTranslationChange(record.id, e.target.value)}
        />
      ),
    },
    {
      width: 100,
      title: "الكلمة",
      align: "right",
      dataIndex: "question",
      key: "question",
      render: (text) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
    {
      width: 100,
      title: "الدرجة",
      align: "right",
      dataIndex: "degree",
      key: "degree",
      render: (text) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
  ];

  console.log(paragrapghQuestions);
  return (
    <Modal
      open={showModal}
      footer={null}
      onCancel={() => setShowModal(false)}
      width={1000}
    >
      <div className="flex flex-col gap-2">
        <div className="w-full mx-auto flex justify-center items-center flex-col gap-5">
          <div className="w-full flex flex-col justify-center items-center">
            {QuizQuestionsData?.map((question, index) => (
              <div
                key={question.id} // Add a unique key
                className="border-2 border-secondary rounded-3xl p-6 w-full mb-10"
              >
                <div className="current-question text-start">
                  {`${index + 1}.`}
                  <div
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: question?.question,
                    }}
                  />
                </div>

                <div className="current-question-degree">
                  <p> درجه {question?.degree} </p>
                </div>

                <div className="current-question-answers">
                  {["answer1", "answer2", "answer3", "answer4"].map(
                    (answer, i) => (
                      <div key={i}>
                        <span>{i + 1} </span>
                        <p
                          dangerouslySetInnerHTML={{ __html: question[answer] }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
            {paragrapghQuestions?.map((paragrapgh, index) => (
              <div
                key={paragrapgh.id}
                className="border-2 border-secondary rounded-3xl p-6 w-full mb-10"
              >
                <div className="mb-6 border-2 border-secondary w-full rounded-3xl p-6 ">
                  <p className="text-3xl font-bold ">{paragrapgh.paragraph}</p>
                </div>
                {paragrapgh?.questions?.map((question) => (
                  <div
                    className=" border-2 border-secondary p-10 mb-10 rounded-3xl w-full"
                    // className="border-2 w-full rounded-3xl p-6 flex items-center justify-between text-start"
                  >
                    <div className="current-question text-start">
                      {`${index + 1}.`}
                      <div
                        className="text-black"
                        dangerouslySetInnerHTML={{
                          __html: question?.question,
                        }}
                      />
                    </div>

                    <div className="current-question-degree">
                      <p> درجه {question?.degree} </p>
                    </div>

                    <div className="current-question-answers">
                      {["answer1", "answer2", "answer3", "answer4"].map(
                        (answer, i) => (
                          <div key={i}>
                            <span>{i + 1} </span>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: question[answer],
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {translateData?.length > 0 && (
          <div className="mt-10 space-y-5 w-full">
            <Table
              dataSource={tableData}
              columns={Translationcolumns}
              pagination={false}
              className="bg-white shadow-2xl border border-secondary rounded-2xl p-1 w-full mx-auto"
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
